import config from "@/config";
import env from "@/env";
import LocaleService from "@/services/LocaleService";
import { useAuthStore } from "@/stores/useAuthStore";
import { RoleEnums } from "@/enums/RoleEnums";

export const routes = config.routes;

export const staticPageRoute = (slug) => {
	if (!slug) {
		return false;
	}

	return `/${LocaleService.getLocale()}/${slug}`;
};

export const routeTo = (name, params = {}, query = {}, hash = "") => {
	if (!name) return null;

	if (!params) params = {};

	return {
		name: name,
		params: {
			...{ locale: LocaleService.getLocale() },
			...params,
		},
		query: query,
		hash: hash,
	};
};

export const listingMenuItemLink = (name, uuid, get = null, $query = {}, base = null) => {
	let baseURL = base;
	if (base === null) baseURL = `${env.API_URL}/${LocaleService.getLocale()}/`;
	else if (base === false) baseURL = "";

	let url = "";

	const params = [name];
	if (uuid) params.push(uuid);
	if (!!get) {
		params.push(get);
	}
	url = params.join("/");
	if (!_.isEmpty($query)) {
		url +=
			"?" +
			Object.keys($query)
				.map((key) => key + "=" + $query[key])
				.join("&");
	}

	return `${baseURL}${url}`;
};

export const defaultRoute = () => {
	const authStore = useAuthStore();

	switch (authStore.getUserRoleId) {
		case RoleEnums.ADMIN:
			return routes.home;
			break;
		case RoleEnums.REDACTOR:
			return routes.home;
			break;
		case RoleEnums.USER_ADMIN:
			return routes.home;
			break;
		case RoleEnums.USER_WORKER:
			return routes.home;
			break;
		case RoleEnums.SUPER_REDACTOR:
			return routes.home;
			break;
		default:
			return routes.home;
			break;
	}

	return "";
};

export default {
	routes,
	staticPageRoute,
	routeTo,
	defaultRoute,
	listingMenuItemLink,
};
